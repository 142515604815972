<template>
    <div>
      <div class="content">
        <ul>
          <div class="center">
            <li v-for="(item, index) in newList" :key="index" @click="details(item.id)">
              <img :src="item.picPathHttpUrl" height="253" width="556" alt=""/>
              <div class="fontDiv">
                <span class="title">
                  {{ item.title }}
                </span>
                <span class="many">
                {{ item.shortDesc }}
              </span>
              </div>
            </li>
          </div>
        </ul>
      </div>
      <!--    分页-->
      <div class="pageInfo">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-size="size"
            layout="total, prev, pager, next, jumper"
            :total="recordsTotal"
            style="margin-top: -25px">
        </el-pagination>
        </div>
    </div>
</template>

<script>
import { newsQueryList } from "../../api/industryInformation";
export default {
  name: "EnterpriseDynamics",
  data(){
    return{
     newList:[],
      recordsTotal: null, // 数据总数
      current:1,
      size:6,
        params:{
          typeId:1
        }
    }
  },
  mounted() {
  },
  watch:{
    $route(to,from){
      console.log('to',to)
      let typeId = this.$route.query.id
      console.log(typeId,'123123123123');
      this.params.typeId = typeId
      localStorage.setItem('typeId', this.params.typeId)
      console.log('this.params.typeId', this.params.typeId)
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList(){
      // console.log('this.params',this.params)
      this.params.typeId = this.$route.query.id || 1
      // console.log(localStorage.getItem('typeId'));
      if(this.params.typeId == null){
        this.params.typeId = 1
      }
      newsQueryList(this.current, this.size, this.params).then(res => {
          console.log('res',res)
        this.newList = res.records
        this.recordsTotal = res.total
      })
    },
    details(id) {
      this.$router.push({name: 'informaDet', query: {id: id}})
    },
    //  分页
    handleCurrentChange(val) {
      this.current = val
      this.getList()
    }
  }
}
</script>

<style scoped lang="less">
  .content{
    //margin-left: -7px;
    ul{
      //display: flex;
      //flex-direction: row;
      //flex-wrap: wrap;
      width: 100%;
      margin: 0 auto;
      .center{
        //width: 1238px;
        margin: 0px auto;
        //width: 100%;
        /* margin: 0 auto; */
        //display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        li{
          padding-left: 23px;
          margin: 0px 21px 100px 0;
          //width: 550px;
          display: inline-block;
          position: relative;
          .fontDiv{
            transition-duration:1s;
            width: 80%;
            background-color: #Fff;
            position: absolute;
            top: 162px;
            padding: 40px 30px;
            span{
              display: block;
              color: rgb(45,45,47);
              font-size: 18px;
            }
            .title{
              margin: 15px 0px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .many{
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              font-size: 14px;
              color: rgb(102,102,102) !important;
            }
          }
        }
      }
    }
  }
  .pageInfo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 35px auto;
  }
  .fontDiv:hover{
    box-shadow: 2px 4px 20px #ccc;
    cursor:pointer;
  }
  //img{
  //  transition-duration:1s;
  //}
  //img:hover{
  //  height:300px;
  //  width: 600px;
  //}
</style>
